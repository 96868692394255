<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

import Topbar from '../components/Topbar.vue'
import Sidebar from '../components/Sidebar.vue'
import CompanyDetails from '../components/CompanyDetails.vue'
import CompanyEdit from '@/components/CompanyEdit.vue'
import Cancellation from '../components/Cancellation.vue'
import LoggedIn from '@/components/LoggedIn.vue'
import Notifications from '@/components/Notifications.vue'
</script>

<template>
    <LoggedIn></LoggedIn>
    <div id="wrapper">
        <Sidebar></Sidebar>
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
            <div id="content">
                <Topbar></Topbar>
                <div class="container-fluid">
                    <!-- Content Row -->
                    <div class="row">
                        <div class="col-xl-6 col-md-6 mb-12">
                            <div class="card border-left-secondary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <CompanyDetails v-if="store.editmode == false"></CompanyDetails>
                                            <CompanyEdit v-if="store.editmode == true"></CompanyEdit>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-6 mb-12">
                            <div class="card border-left-secondary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <Cancellation></Cancellation>
                                            <br/>
                                            <Notifications></Notifications>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>