<script setup>
import { useMainStore } from '@/stores/main';
const store = useMainStore()
import Licences from '@/components/Licences.vue';

const changeType = (type) => {
    store.current_product_type = type
}
</script>

<template>
    <nav class="navbar navbar-expand-lg navbar-light m-2">
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li :class="store.current_product_type == 'VE' ? 'nav-item active' : 'nav-item'">
                    <div class="nav-link" @click="changeType('VE')">Proxmox Virtual Environments</div>
                </li>
                <li :class="store.current_product_type == 'PBS' ? 'nav-item active' : 'nav-item'">
                    <div class="nav-link" href="#" @click="changeType('PBS')">Proxmox Backup Server</div>
                </li>
                <li :class="store.current_product_type == 'MG' ? 'nav-item active' : 'nav-item'">
                    <div class="nav-link" href="#" @click="changeType('MG')">Proxmox Mail Gateway</div>
                </li>
            </ul>
        </div>
    </nav>
    <div class="row">
        <Licences></Licences>
    </div>
</template>