<script setup>
import { useMainStore } from '@/stores/main'
import Imprint from './Imprint.vue';
const store = useMainStore()
</script>

<template>
    <span class="text-center">
        <a class="small" href="/login">
            {{ store.translate("login") }}
        </a>
    </span>
    &nbsp;
    <span class="text-center">
        <a class="small" href="/register">
            {{ store.translate("register_account") }}
        </a>
    </span>
    &nbsp;
    <span class="text-center">
        <a class="small" href="/reset">
            {{ store.translate("forgot_pw_button") }}
        </a>
    </span>
    &nbsp;
    <Imprint></Imprint>
</template>

<style scoped></style>
