<script setup>
import { useMainStore } from '@/stores/main';
import { RouterLink } from 'vue-router';
document.querySelector("body").classList.add('bg-gradient-primary')
const store = useMainStore();
store.getLicences()

function getClassNames() {
    if (store.current_shop_product_id > 0) {
        return '';
    }
    return 'col-xl-3 col-md-6 mb-4';
}

const productConfig = [
    {
        id: 1,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_community_support"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_support_ticket"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_guaranteed_response_time"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small"
    }, {
        id: 2,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_3_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_1_day_response_time"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small_production"
    }, {
        id: 3,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_10_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_4_hours_response_time"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small_business"
    }, {
        id: 4,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_unlimited_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_2_hours_response_time"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_big_cluster"
    }, {
        id: 5,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_community_support"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_support_ticket"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_guaranteed_response_time"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small"
    }, {
        id: 6,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_5_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_1_day_response_time"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small_production"
    }, {
        id: 7,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_15_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_4_hours_response_time"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small_business"
    }, {
        id: 8,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_unlimited_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_2_hours_response_time"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_big_cluster"
    }, {
        id: 9,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_community_support"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_support_ticket"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_guaranteed_response_time"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small"
    }, {
        id: 10,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_5_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_1_day_response_time"
            }, {
                featureCheckbox: false,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small_production"
    }, {
        id: 11,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_15_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_4_hours_response_time"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_small_business"
    }, {
        id: 12,
        features: [
            {
                featureCheckbox: true,
                translationKeyword: "licence_feature_enterprise_repo"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_stable_version"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_all_features"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_customer_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_unlimited_support_tickets"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_2_hours_response_time"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_ssh_remote_support"
            }, {
                featureCheckbox: true,
                translationKeyword: "licence_feature_renew_yearly"
            }
        ],
        translationRecommend: "licence_recommend_big_cluster"
    }
]

function getConfigFromId(id) {
    for (let index = 0; index < productConfig.length; index++) {
        const element = productConfig[index];
        if (element.id == id) {
            return element;
        }
    }
    return {};
}
</script>

<template>
    <div v-for="product in store.filteredShop(store.current_product_type)" :class="getClassNames()">
        <div v-if="!store.current_shop_product_id || store.current_shop_product_id == product.id"
            :class="[product.highlight ? 'border-left-primary card shadow py-2' : 'border-left-secondary card shadow py-2']">
            <div class="card-body">
                <div class="row min-height-m no-gutters align-items-center">
                    <div class="col-8 mr-2">
                        <div class="font-weight-bold text-primary text-uppercase mb-1">
                            {{ product.name }}
                        </div>
                        <div class="licence_price">
                            {{ store.formatPrice(product.price_1_cpu) }}
                        </div>
                        <div class="text-xs licence_price_underline">
                            {{ store.translate('licence_price_information') }}
                        </div>
                    </div>
                    <div class="col-2">
                        <i class="fas fa-newspaper fa-3x text-gray-300"></i>
                    </div>
                </div>
                <hr>
                <div class="row licence_feature no-flex-wrap" v-for="feature in getConfigFromId(product.id).features">
                    <div class="pr-2" v-if="feature.featureCheckbox">
                        <i class="fas fa-check"></i>
                    </div>
                    <div class="pr-2" v-else="feature.featureCheckbox">
                        <i class="fas fa-minus"></i>
                    </div>
                    <div class="licence_feature_text">
                        {{ store.translate(feature.translationKeyword) }}
                    </div>
                </div>
                <br />
                <div class="row p-2">
                    <p>{{ store.translate(getConfigFromId(product.id).translationRecommend) }}</p>
                </div>
                <div v-if="store.current_shop_product_id == 0" class="pt-4">
                    <RouterLink class="btn btn-primary btn-user btn-block"
                        :to="{ name: 'Shop', params: { id: product.id } }">
                        {{ store.translate("buy") }}
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
