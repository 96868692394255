<script setup>
import { useRoute, useRouter} from 'vue-router';
import { useMainStore } from '@/stores/main';
const store = useMainStore()
import Imprint from '@/components/Imprint.vue';
import LicenceCPUPicker from '@/components/LicenceCPUPicker.vue';
import LicenceTypeWrapper from '@/components/LicenceTypeWrapper.vue';
import Licences from '@/components/Licences.vue';
const route = useRoute();
const router = useRouter();

if (store.loggedin) {
    store.prefillForm()
}

router.afterEach(() => {
    if (route.params.id) {
        store.current_shop_product_id = route.params.id
        store.shop_step = 1
    } else {
        store.current_shop_product_id = 0
        store.shop_step = 0
    }
})
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9">
            <a href="/">
                <img src="../assets/logo.jpg" class="logo-cover">
            </a>
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <div v-if="store.shop_step == 0">
                        <div class="p-2">
                            <h1>
                                Proxmox Subscriptions
                            </h1>
                            <h2>
                                Subscriptions im Vergleich
                            </h2>
                        </div>
                        <LicenceTypeWrapper></LicenceTypeWrapper>
                        <div class="row">
                            <div class="col-6 subscription-faq">
                                <h3>
                                    FAQ
                                </h3>
                                <ol>
                                    <li>
                                        {{ store.translate("faq_licence_open_source_headline") }}<br />
                                        {{ store.translate("faq_licence_open_source_text") }}
                                    </li>
                                    <li>
                                        {{ store.translate("faq_licence_duration_headline") }}<br />
                                        {{ store.translate("faq_licence_duration_text") }}
                                    </li>
                                    <li>
                                        {{ store.translate("faq_licence_bill_headline") }}<br />
                                        {{ store.translate("faq_licence_bill_text") }}
                                    </li>
                                    <li>
                                        {{ store.translate("faq_licence_payment_headline") }}<br />
                                        {{ store.translate("faq_licence_payment_text") }}
                                    </li>
                                    <li>
                                        {{ store.translate("faq_licence_pay_years_headline") }}<br />
                                        {{ store.translate("faq_licence_pay_years_text") }}
                                    </li>
                                </ol>
                            </div>
                            <div class="col-6 subscription-footer-text">
                                <h3>
                                    Subscription
                                </h3>
                                <p>
                                    {{ store.translate("subscription_footer_text_1") }}
                                </p>
                                <p>
                                    {{ store.translate("subscription_footer_text_2") }}
                                </p>
                                <p>
                                    {{ store.translate("subscription_footer_text_3") }}
                                </p>
                                <a target="_blank"
                                    href="https://proxmox.com/en/downloads/item/proxmox-ve-subscription-agreement">
                                    Proxmox Subscription Agreement
                                </a>
                            </div>
                        </div>
                    </div>

                    <div v-if="store.shop_step == 1">
                        <div class="p-2">
                            <h1>
                                Proxmox Subscriptions
                            </h1>
                            <h2>
                                {{ store.translate("order") }}
                            </h2>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-md-4 mb-6">
                                <Licences></Licences>
                                <br/>
                                <LicenceCPUPicker></LicenceCPUPicker>
                            </div>
                            <div class="col-xl-1 d-xl-block d-md-none"></div>
                            <div class="col-xl-7 col-md-8 mb-6">
                                <h2>
                                    {{ store.translate("form_address") }}
                                </h2>
                                <div class="form-group">
                                    <label>{{ store.translate("email") }}</label><br />
                                    <input type="text" class="form-control form-control-user" v-model="store.email">
                                    <span class="text-s">{{ store.translate("licence_delivery_via_email") }}</span>
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("company_name") }}</label>
                                    <input type="text" class="form-control form-control-user"
                                        v-model="store.pending_name">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("firstname") }}</label>
                                    <input type="text" class="form-control form-control-user" v-model="store.firstname">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("lastname") }}</label>
                                    <input type="text" class="form-control form-control-user" v-model="store.lastname">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("phone") }} ({{ store.translate("optional") }})</label>
                                    <input type="text" class="form-control form-control-user" v-model="store.phone">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("street") }}</label>
                                    <input type="text" class="form-control form-control-user"
                                        v-model="store.pending_street">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("postcode") }}</label>
                                    <input type="text" class="form-control form-control-user"
                                        v-model="store.pending_postcode">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("city") }}</label>
                                    <input type="text" class="form-control form-control-user"
                                        v-model="store.pending_city">
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("country") }}</label><br />
                                    <select class="form-control form-control-user" v-model="store.pending_country">
                                        <option value="DE" selected>Deutschland</option>
                                        <option value="FR">Frankreich</option>
                                        <option value="LUX">Luxemburg</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>{{ store.translate("ustid") }} ({{ store.translate("optional") }})</label>
                                    <input type="text" class="form-control form-control-user"
                                        v-model="store.pending_ustid">
                                </div>
                                <p>
                                    {{ store.getProductInfos(store.current_shop_product_id, store.current_product_type).name }}, 
                                    {{ store.translate("total_price") }}:
                                    {{ store.formatPrice(store.calcVEPrice(store.current_product_type)) }}
                                    <span class="text-xs">
                                        {{ store.translate("price_info") }}
                                    </span>
                                </p>
                                <button class="btn btn-primary btn-user btn-block" :disabled="store.loading"
                                    @click="store.orderLicence" @submit.prevent>
                                    {{ store.translate("order_button") }}
                                </button>
                                <p>
                                    {{ store.errorMessage }}
                                </p>
                            </div>
                            <div class="col-xl-1 d-xl-block d-md-none"></div>
                        </div>
                    </div>
                    <div class="p-4">
                        <Imprint></Imprint>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
