<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

function registerCompany() {
    store.registerCompany()
}
</script>

<template>
    <div class="company">
        <div class="form-group">
            <label>{{ store.translate("email") }} *</label>
            <input type="text" class="form-control form-control-user" v-model="store.email">
        </div>
        <div class="form-group">
            <label>{{ store.translate("password") }} *</label>
            <input type="password" class="form-control form-control-user" v-model="store.password" min="10">
            <span class="small">
                {{ store.translate("password_policy") }}
            </span>
        </div>
        <div class="form-group">
            <label>{{ store.translate("company_name") }}</label>
            <input type="text" class="form-control form-control-user" v-model="store.pending_name">
        </div>
        <div class="form-group">
            <label>{{ store.translate("firstname") }} *</label>
            <input type="text" class="form-control form-control-user" v-model="store.firstname">
        </div>
        <div class="form-group">
            <label>{{ store.translate("lastname") }} *</label>
            <input type="text" class="form-control form-control-user" v-model="store.lastname">
        </div>
        <div class="form-group">
            <label>{{ store.translate("phone") }}</label>
            <input type="text" class="form-control form-control-user" v-model="store.phone">
        </div>
        <div class="form-group">
            <label>{{ store.translate("street") }} *</label>
            <input type="text" class="form-control form-control-user" v-model="store.pending_street">
        </div>
        <div class="form-group">
            <label>{{ store.translate("postcode") }} *</label>
            <input type="text" class="form-control form-control-user" v-model="store.pending_postcode">
        </div>
        <div class="form-group">
            <label>{{ store.translate("city") }} *</label>
            <input type="text" class="form-control form-control-user" v-model="store.pending_city">
        </div>
        <div class="form-group">
            <label>{{ store.translate("country") }} *</label><br />
            <select class="form-control form-control-user" v-model="store.pending_country">
                <option value="DE" selected>{{ store.translate("germany") }}</option>
                <option value="FR">{{ store.translate("france") }}</option>
                <option value="LUX">{{ store.translate("luxembourg") }}</option>
            </select>
        </div>
        <div class="form-group">
            <label>{{ store.translate("ustid") }}</label>
            <input type="text" class="form-control form-control-user" v-model="store.pending_ustid">
        </div>
        <p>
            * {{ store.translate("mandatory_field") }}
        </p>
        <div class="form-group">
            <input id="checkbox-notifications" type="checkbox" class="mr-2" v-model="store.notifications">
            <label for="checkbox-notifications" class="d-inline">{{ store.translate("get_notifications") }}</label>
        </div>
    </div>
    <p>
        {{ store.errorMessage }}
    </p>
    <button class="btn btn-primary btn-user btn-block" :disabled="store.loading" @click="registerCompany"
        @submit.prevent>
        {{ store.translate("register") }}
    </button>
</template>

<style scoped></style>
