<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()
</script>

<template>
    <div v-if="store.current_product_type == 'VE'">
        <h5>
            {{ store.translate("licence_cpu_headline") }}:
        </h5>

        <input type="radio" id="cpu_1" class="mr-1" value="1" v-model="store.current_cpu_value" />
        <label for="cpu_1" class="text-lg">
            {{ store.translate("radio_cpu_1") }}
        </label>
        <br/>

        <input type="radio" id="cpu_2" class="mr-1" value="2" v-model="store.current_cpu_value" />
        <label for="cpu_2" class="text-lg">
            {{ store.translate("radio_cpu_2") }}
        </label>
        <br/>

        <input type="radio" id="cpu_4" class="mr-1" value="4" v-model="store.current_cpu_value" />
        <label for="cpu_4" class="text-lg">
            {{ store.translate("radio_cpu_4") }}
        </label>
        <br/>

        {{ store.translate("total_price") }}:
        {{ store.formatPrice(store.calcVEPrice(store.current_product_type)) }}<br/>
        <p class="text-xs">
            {{ store.translate("price_info") }}
        </p>        
    </div>
</template>

<style scoped></style>
